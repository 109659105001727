import React from 'react'

const Footer = () => {
  const year = (new Date()).getFullYear();
  return (
    <div className="w-screen bg-black text-zinc-400 flex justify-center md:px-12">
      <div className='w-20 md:w-80'></div>
      <div className='border-t border-zinc-900 flex justify-center items-center h-16 w-full'>
        <div className='text-sm'>Copyright © {year} Makeweb.ai</div>
      </div>
      
    </div>
  )
}

export default Footer