import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../hooks/useAuth';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import LoadingTile from './LoadingTile';
import TemplateTile from './TemplateTile';
import useDocTitle from '../../../hooks/useDocTitle';

const Templates = () => {
  useDocTitle('Templates | Makeweb.ai');
  const {auth} = useAuth();

  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [templatesError, setTemplatesError] = useState("");
  const axiosPrivate = useAxiosPrivate();
  useEffect(()=>{
    const fetchTemplates = async () => {
      setTemplatesLoading(true);
      try {
        const resp = await axiosPrivate.get("/website/template");
        if (resp.status == 200) {
          setTemplates((resp.data).map(site => {site.id = site._id; return site;}));
          // console.log(resp.data);
        } else {
          throw Error("Unexpected response from server");
        }
      } catch(err) {
        setTemplatesError(err.mesaage);
        console.log(err);
      } finally {
        setTemplatesLoading(false)
      }
    }
    if (auth?.accessToken) {
      fetchTemplates()
    }
  },[auth])

  return (
    <div className="flex flex-col gap-14">
      <div className='flex flex-col gap-2'>
        <p className="text-xl font-semibold text-zinc-200">Starter Templates</p>
      </div>
      <div className="gap-16 flex flex-wrap">
        {templatesLoading? 
          [1,2,3,4].map(num => <LoadingTile key={num}/>)
          :
          templates.sort((p1, p2) => p2.createdAt - p1.createdAt).map(site=> <TemplateTile key={site._id} site={site}/>)
        }
      </div>
    </div>
  )
}

export default Templates