import React, { useContext } from 'react'
import UserContext from '../../../../context/UserContext';
import { useLogout } from '../../../../hooks/useLogout';
import { Link } from 'react-router-dom';
import { GoFileDirectoryFill } from "react-icons/go";
import { HiTemplate } from "react-icons/hi";
import { MdOutlinePayment } from "react-icons/md";
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { IoMdHelpCircleOutline } from "react-icons/io";


const NavPanel = () => {
  const {isUserLoading, userNameAbbr, user} = useContext(UserContext);
  const logOut = useLogout();

  return (
    <div className="fixed z-10 left-0 top-0 w-16 md:w-80  px-4 md:px-12 pt-11 md:pt-24 min-h-screen bg-black border-r border-zinc-800">
      <div className='flex flex-col gap-6 text-zinc-400 '>
        {
          isUserLoading?
          <>
            <div className="bg-zinc-900 rounded-xl w-7 md:w-52 h-14 animate-pulse"></div>
            <div className="bg-zinc-900 rounded-xl w-7 h-7 md:w-52 md:h-5 animate-pulse mt-16"></div>
            <div className="bg-zinc-900 rounded-xl w-7 h-7 md:w-52 md:h-5 animate-pulse"></div>
            <div className="bg-zinc-900 rounded-xl w-7 h-7 md:w-52 md:h-5 animate-pulse"></div>
            <div className="bg-zinc-900 rounded-xl w-7 h-7 md:w-52 md:h-5 animate-pulse"></div>
            <div className="bg-zinc-900 rounded-xl w-7 h-7 md:w-52 md:h-5 animate-pulse"></div>
          </>
          :
          <>
            <div className='flex flex-col md:flex-row gap-3 items-center'>
              <div className="rounded-lg flex bg-lime-600 text-zinc-300 w-11 h-11 font-bold justify-center items-center">
                <span className='text-sm font-bold capitalize text-white md:hidden'>{userNameAbbr}</span>
                <svg xmlns="http://www.w3.org/2000/svg" className="hidden md:block lucide lucide-mouse-pointer-square p-1"  width='38px' viewBox="0 0 24 24"  stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fillOpacity="0"><path d="M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6"></path><path d="m12 12 4 10 1.7-4.3L22 16Z"></path></svg>
              </div>
              <div className='flex-col hidden md:flex'>
                <span className='text-zinc-300 text-sm font-semibold capitalize'>{user.fname && user.fname} {user.lname && user.lname}
                  <span className='text-white'>{`${user.roles.Admin? ' (Admin)' : user.roles.Enterprise? ' (Enterprise)': user.roles.Pro? ' (Pro)': user.roles.Basic? ' (Basic)': ''}`}</span>
                </span>
                <span className='text-zinc-400 text-xs text-semibold'>{user.email}</span>
              </div>
            </div>
          
            <div className='mt-8 md:mt-16 flex flex-col gap-8 md:gap-6'>
              <Link to="/">
                <div className='flex items-center gap-5 text-zinc-400 hover:text-zinc-200 duration-150'>
                  <GoFileDirectoryFill className='w-7 h-7 md:w-5 md:h-5'/>
                  <span className='hidden md:block'>Websites</span>
                </div>
              </Link>
              <Link to="/templates">
                <div className='flex items-center gap-5 text-zinc-400 hover:text-zinc-200 duration-150'>
                  <HiTemplate className='w-7 h-7 md:w-5 md:h-5'/>
                  <span className='hidden md:block'>Templates</span>
                </div>
              </Link>
              <Link to="/subscription">
                <div className='flex items-center gap-5 text-zinc-400 hover:text-zinc-200 duration-150'>
                  <MdOutlinePayment className='w-7 h-7 md:w-5 md:h-5'/>
                  <span className='hidden md:block'>Subscription</span>
                </div>
              </Link>
              <Link to="/help">
                <div className='flex items-center gap-5 text-zinc-400 hover:text-zinc-200 duration-150'>
                  <IoMdHelpCircleOutline className='w-7 h-7 md:w-5 md:h-5'/>
                  <span className='hidden md:block'>Help</span>
                </div>
              </Link>
              
              <button onClick={logOut}  className='flex items-center gap-5 text-zinc-400 hover:text-zinc-200 duration-150'>
                <RiLogoutCircleRLine className='w-7 h-7 md:w-5 md:h-5'/>
                <span className='hidden md:block'>Logout</span>
              </button>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default NavPanel