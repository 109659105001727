import React, { useContext, useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import { v4 as uuid } from 'uuid';
import CanvasContext from '../../../../context/CanvasContext';
import { assetCategories, assets } from '../../../../componentStates/assets';
import Asset from '../comps/Asset';
import { FaAngleRight } from "react-icons/fa6";
import DialogBox from '../comps/DialogBox';

const AssetPanel = () => {
  const { selectedAssetCateg, setSelectedAssetCateg } = useContext(CanvasContext);
  const [dialogBoxAppear, setDialogBoxAppear] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [searchFocus, setSearchFocus] = useState(false);
  const[filteredAssets, setFilteredAssets] = useState([]); 

  useEffect(()=>{
    setFilteredAssets(
      assets.filter(asset => {
      if (searchStr) {
        setDialogBoxAppear(true);
        return asset.name.toLowerCase().includes(searchStr.toLowerCase());
      } else if (selectedAssetCateg) {
        return asset.categ.includes(selectedAssetCateg)
      } else
        return true;
      })
    )
  },[searchStr, selectedAssetCateg])

  return (
    <div onMouseLeave={()=>{setDialogBoxAppear(false)}}  className="fixed z-10 left-0 pt-20 bg-black border-r border-zinc-800 h-[100%] w-64 select-none ">
      <div className="flex flex-col gap-4 h-[100%]">
        <div className='flex flex-col gap-4 px-4'>
          <div className="flex text-white gap-0 bg-zinc-900 rounded-lg p-[3px] w-full">
            <button className='bg-zinc-700 text-zinc-200 font-semibold py-1 w-[50%] rounded-md text-xs'>Components</button>
            <button className='bg-zinc-900 text-zinc-400 py-1 w-[50%] rounded-md text-xs hover:text-lime-600'>Layers</button>
          </div>
          <div className={`w-full flex items-center border-b p-1 focus:border-lime-600 ${searchFocus? 'border-zinc-300':'border-zinc-800'}`}>
            <FiSearch className={`w-4 h-4  ${searchFocus? 'text-zinc-300':'text-zinc-600'}`} />
            <input onFocus={()=>setSearchFocus(true)} onBlur={()=>setSearchFocus(false)} value={searchStr} onChange={(e)=>{setSearchStr(e.target.value)}} type="text" className='bg-black placeholder:text-zinc-700 flex-grow text-md outline-none text-zinc-400 px-2 font-secondary placeholder:text-sm' placeholder='Search'/>
          </div>
        </div>
        <div className='w-full relative px-4'>
          <div className={`flex flex-col gap-1 py-2 h-[${window.innerHeight-200}px] customScroll overflow-auto`}>
            {assetCategories.map(categ => { 
              return (
                <button onMouseOver={()=>{setDialogBoxAppear(true);setSelectedAssetCateg(categ.categ);}} key={uuid()} className={`${selectedAssetCateg===categ.categ? 'opacity-100 bg-zinc-900 ':'' } opacity-70 hover:opacity-100 text-white  to-lime-600  hover:bg-zinc-900 duration-100 text-xs font-semibold rounded-xl w-full p-2 flex gap-1 items-center justify-between`}>
                  <div className='flex gap-2 items-center'>
                    <div className='bg-zinc-700 rounded-lg w-9 h-9 flex justify-center items-center'>{categ?.icon && categ.icon}</div>
                    {categ.name}
                  </div>
                  <FaAngleRight className='w-3 h-auto'/>
                </button>)
            })}
          </div>
        </div>
        <DialogBox appear={dialogBoxAppear} setAppear={setDialogBoxAppear}>
          <div className='z-50 fixed left-[256px] bg-black w-64 top-14 h-[100%] px-4 flex justify-center border-r border-zinc-800'>
              <div className='h-[92%] overflow-auto flex flex-col gap-4 py-4 flex-grow customScroll'>
                {filteredAssets.map((asset, index) => {
                  return (
                    <Asset key={uuid()} asset={asset} />
                  );
                })}
              </div>
          </div>
        </DialogBox>
        
      </div>
      
    </div>
  )
}

export default AssetPanel