import React, { useState } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { RiTwitterXLine } from 'react-icons/ri';
import { useAuth } from '../hooks/useAuth';
import { BASE_URL } from '../api/axios';
import useDocTitle from '../hooks/useDocTitle';


const LogIn = () => {
  useDocTitle('Login | Makeweb.ai');
  // const [emailUpdates, setEmailUpdates] = useState(true);
  const { auth } = useAuth();
  const location = useLocation();
  return auth.accessToken? <Navigate to='/' state={{from: location}} replace /> : 
  (
    <div className="bg-black text-white  min-h-screen font-sans flex justify-center items-center">
      <div className="border border-zinc-800 rounded-2xl px-10 py-20 flex flex-col items-center gap-5">
        <div className="flex border-t- font- items-center gap-2">
        <Link to="/" className='flex items-center gap-1 text-white text-2xl font-bold font-webstitch'><img src="/icon.webp" className='h-9 w-9'/>Makeweb.ai</Link>
        </div>
        <p className="text-md mt-5">Sign in to start the magic</p>
        <a href={`${BASE_URL}/auth/google`} className="bg-zinc-900 text-lg border border-zinc-800 rounded-md px-10 md:px-20 py-2 text-zinc-300 tracking-tighter flex items-center gap-2 hover:bg-zinc-800 duration-300">
          <FcGoogle className='inline-block'/>
          Continue with Google
        </a>
        <button disabled href={`${BASE_URL}/auth/twitter`} className="bg-zinc-900 disabled:bg-black text-lg border border-zinc-800 rounded-md px-10 md:px-20 py-2 text-zinc-300 tracking-tighter flex items-center gap-2 duration-300">
          <RiTwitterXLine className='inline-block'/>
          Continue with Twitter
        </button>
        {/* <div className='text-xs flex flex-wrap w-0'><input type='checkbox' value={emailUpdates} onChange={()=>{}}/> I acknowledge to receive product update and offers from Makeweb.ai</div> */}
      </div>
    </div>
  )
}

export default LogIn;