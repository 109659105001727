import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { jsonToCode } from '../../componentStates/codeParser';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { setColorProperty } from '../../helpers/helpers';
const gfonts = require("google-fonts-complete");

const HtmlPreview = () => {
  const [code, setCode] = useState({});
  const { siteId } = useParams();
  const [ searchParams ] = useSearchParams();
  const {auth} = useAuth();

  const [headScript, setHeadScript] = useState("");
  const [bodyScript, setBodyScript] = useState("");

  const extractScripts = (scriptString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = scriptString;
    const scriptElements = Array.from(tempDiv.getElementsByTagName('script'));

    return scriptElements.map((script, i) => {
      if (script.src) {
        return (
          <Helmet><script key={i} async={script.async} src={script.src}></script></Helmet>
        );
      } else {
        return (
          <Helmet>
            <script key={i} >{`try{${script.innerHTML}}catch(err){console.log(err)}`}</script>
          </Helmet>
        );
      }
    });
  };

  const loadFontInApp = (fontValue) => {
    const fontName = String(fontValue.split(",")[0]).replaceAll("\'","");
    const weights = Object.keys(gfonts[fontName].variants.normal);
    const fontUrl = `https://fonts.googleapis.com/css2?family=${fontName.split(" ").join("+")}:wght@${weights.join(';')}&display=swap`;
    const link = document.createElement('link');
    link.href = fontUrl;
    link.rel = 'stylesheet';
    link.crossOrigin="anonymous";
    document.head.appendChild(link);
  };
  
  const axiosPrivate = useAxiosPrivate();
  useEffect(()=>{
    const fetchPage = async () => {
      try {
        const resp = await axiosPrivate.get(`/website/${siteId}`);
        
        if (!resp.data || resp.data._id !== siteId) {
          throw Error("Unexpected Response from server");
        }
        const site = resp.data;
        const pagePath = searchParams.get('page') || "/";
        if (Object.keys(site.devPages).length && site.devPages[pagePath]?.code) {
          setCode(jsonToCode(site.devPages[pagePath]?.code));
          if (site.devTheme.colorPalette) {
            Object.entries(site.devTheme.colorPalette).forEach(([colorType, color]) => setColorProperty(colorType, color))
          }
          if (site.devTheme.headingFont) {
            loadFontInApp(site.devTheme.headingFont);
            document.documentElement.style.setProperty('--heading-font', site.devTheme.headingFont);
          }
          if (site.devTheme.bodyFont) {
            loadFontInApp(site.devTheme.bodyFont);
            document.documentElement.style.setProperty('--body-font', site.devTheme.bodyFont);
          }
          if (site?.devPages && site.devPages[pagePath]?.headScript) {
            setHeadScript(site.devPages[pagePath]?.headScript);
          }
          if (site?.devPages && site.devPages[pagePath]?.bodyScript) {
            setBodyScript(site.devPages[pagePath]?.bodyScript);
          }
        } else {
          throw Error("Unexpected Response from server");
        }
      } catch (err) {
        console.log(err);
        // navigate('/');
      }
    }
    if (auth.accessToken) {
      fetchPage();
    }
  },[auth])

  return (
    <> 
    {code && code.html? 
      <>
        {code.html &&
          <div dangerouslySetInnerHTML={{ __html: code.html }} />
        }
        <Helmet>
          {code.script &&
            <script>{code.script}</script>
          }
          {code.bodyStyle &&
            <style>{code.bodyStyle}</style>
          }
        </Helmet>
        {headScript && 
          <>
            {extractScripts(headScript).map((scriptElement) => scriptElement)}
          </>
        }
        {bodyScript && 
          <>
            {extractScripts(bodyScript).map((scriptElement) => scriptElement)}
          </>
        }
      </>
      :
      <div className='w-screen bg-zinc-600 min-h-screen animate-pulse'></div>
    } 
    </>
  )
}

export default HtmlPreview