import React, { useEffect, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { useAuth } from '../../../hooks/useAuth';
import useDocTitle from '../../../hooks/useDocTitle';
import { IoCheckmark } from "react-icons/io5";
import { RxCross2 } from 'react-icons/rx';

const Subscription = () => {
  useDocTitle('Subscription Plans | Makeweb.ai');
  const { auth } = useAuth();
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchErr, setFetchErr] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toDateString()
  }

  useEffect(()=>{
    const fetchSubscription = async () => {
      setIsLoading(true);
      setFetchErr(false);
      try {
        const resp = await axiosPrivate.get("/subscription");
        if (resp.status === 200) {
          setSubscription(resp.data);
          // console.log(resp.data);
        }
      } catch (err) {
        console.log(err);
        if (err.response.status == 500) {
          setFetchErr(true);
          console.log("set to true");
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (auth.accessToken) {
      fetchSubscription();
    }
  },[auth])

  return (
    <div className="flex flex-col gap-12">
      <div className='flex flex-col gap-2'>
        <p className="text-xl font-semibold text-zinc-200">Subscription</p>
        <p className="text-sm text-zinc-400">View and managae your subscription information</p>
      </div>
      {isLoading?
        <div className='flex flex-col animate-pulse gap-6'>
          <div className='w-3/4 bg-zinc-800 rounded-lg h-6'></div>
          {/* <div className='w-3/4 bg-zinc-800 rounded-lg h-6'></div> */}
          <div className='w-1/2 bg-zinc-800 rounded-lg h-6'></div>
        </div>
        :
        <>
          {!subscription?
            fetchErr?
            <div className='text-zinc-300 w-full border border-zinc-800 rounded-lg px-8 py-5 flex flex-col gap-3'>
              <span className=''>We're unable to fetch your subscrption details for unknown reason.</span>
              <span>Please contact our team at <a className='text-lime-400 font-semibold' href='mailto:zishan@makeweb.ai?subject=Lets%20Connect&body=Hi%20Zishan%2C%0A'>zishan@makeweb.ai</a> to resolve this issue.</span>
            </div>
            :
            <div>
              <div className='text-zinc-300 w-full border border-zinc-800 rounded-lg px-8 py-5 flex flex-col gap-3'>
                <p>You do not have a paid subscription plan for Makeweb.ai. Please purchase a plan to get access.</p>
                {/* <p>If you have a paid subscription and you see this screen, please contact our team at <a className='text-lime-400 font-semibold' href='mailto:zishan@makeweb.ai?subject=Lets%20Connect&body=Hi%20Zishan%2C%0A'>zishan@makeweb.ai</a> to resolve this issue.</p> */}
              </div>
              <div className='flex justify-center flex-col md:flex-row w-full my-10 gap-16 md:gap-6 lg:gap-8 xl:gap-10'>
                <div className='border border-zinc-800 bg-zinc-900/30 p-10 pb-6 rounded-2xl w-full sm:w-[300px] md:w-[500px] flex flex-col gap-5'>
                  <div>
                    <p className='text-3xl font-bold text-zinc-200'>Basic</p>
                    <p className='text-sm text-zinc-500'>For individuals just starting up</p>
                  </div>
                  <div className='text-zinc-200'>
                    <span className='text-5xl font-semibold'>
                      <span className='line-through text-zinc-400 font-light mr-1 text-3xl'>
                        $79
                      </span>
                      $24
                    </span>
                    <span className='font-semibold text-lime-300'> per year</span>
                  </div>
                  <div className='border-b border-zinc-800'></div>
                  <div className='flex flex-col gap-3 text-sm text-zinc-300 font-semibold'>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Publish upto 1 site</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>100 AI Prompts / mo</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Drag & Drop Builder</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Templates & UI Blocks</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Connect your own domain</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Free SSL Certificates</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Chat Support</div>
                    <div className='flex gap-3 items-center'><RxCross2 className='w-5 h-auto text-zinc-500'/>Export Your Code</div>
                  </div>
                  <a href='https://payments.makeweb.ai/buy/93cd128f-ecee-4c64-b63a-97c68ede2c42' target='_blank' rel="noreferrer" aria-label="Purchase Makeweb.ai Subscription" className='w-full justify-self-end py-3 rounded-xl bg-lime-700 text-zinc-100 hover:bg-lime-600 duration-200 font-semibold flex justify-center text-lg'>Get This Plan</a>
                </div>

                <div className='relative border border-lime-800 bg-lime-900/5  p-10 pb-8 rounded-2xl w-full sm:w-[300px] md:w-[500px] flex flex-col gap-5'>
                  <div className='w-full text-center absolute top-[-12px] left-0 '><span className='bg-lime-700 text-zinc-100 font-semibold px-4 py-1 text-sm rounded-full'>Recommended</span></div>
                  <div>
                    <p className='text-3xl font-bold text-zinc-200'>Pro</p>
                    <p className='text-sm text-zinc-500'>For individuals, business owners</p>
                  </div>
                  <div className='text-zinc-200'>
                    <span className='text-5xl font-semibold tracking-tighter '>
                      <span className='line-through text-zinc-400 font-light mr-1 text-3xl'>
                        $99
                      </span>
                      $29
                    </span>
                    <span className='font-semibold text-lime-300'> per year <span className='text-zinc-400 font-normal'></span></span>
                  </div>
                  <div className='border-b border-zinc-800'></div>
                  {/* <div className='text-sm flex items-center gap-2'><FaFeatherAlt className='w-3 h-auto text-zinc-300'/> Features / Benefits</div> */}
                  <div className='flex flex-col gap-3 text-sm text-zinc-300 font-semibold'>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Publish upto 10 sites</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Unlimited AI Prompts</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Drag & Drop Builder</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Templates & UI Blocks</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Connect your own domain</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Free SSL Certificates</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Email & Chat Support</div>
                    <div className='flex gap-3 items-center'><IoCheckmark className='w-5 h-auto text-lime-500'/>Export Your Code</div>
                  </div>
                  <a href='https://payments.makeweb.ai/buy/2049dd02-869c-450c-b168-b0475d885697' target='_blank' rel="noreferrer" aria-label="Purchase Makeweb.ai Subscription" className='w-full py-3 text-lg rounded-xl bg-lime-700 text-zinc-100 font-semibold hover:bg-lime-600 duration-200 flex justify-center'>Get This Plan</a>
                </div>
              </div>
              <div className='text-zinc-300 w-full border border-zinc-800 rounded-lg px-8 py-5 flex flex-col gap-3'>
                {/* <p>You do not have a paid subscription plan for Makeweb.ai. Purchase a plan to get access.</p> */}
                <p>If you have a paid subscription and you see this screen, please contact our team at <a className='text-lime-400 font-semibold' href='mailto:zishan@makeweb.ai?subject=Lets%20Connect&body=Hi%20Zishan%2C%0A'>zishan@makeweb.ai</a> to resolve this issue.</p>
              </div>
            </div>
            
            :
            <div className='text-zinc-300 w-full border border-zinc-800 rounded-lg px-8 py-5 flex flex-col gap-2'>
              <div className='flex flex-col md:flex-row justify-between gap-2'>
                <p className='text-lg font-semibold'>{subscription.productName} <span className='capitalize'>{subscription.variantName}</span></p>
                <a href={`${subscription.customerPortalUrl? subscription.customerPortalUrl : ''}`} target='_blank' className='bg-zinc-700 px-4 py-2 rounded-lg font-semibold text-zinc-200 w-fit hover:bg-zinc-600'>Manage</a>
              </div>
              <div className='flex flex-col md:flex-row gap-5 text-zinc-400'>
                <div className='flex items-center gap-2 capitalize'>
                  <div className={'w-2 h-2 rounded-full '+`${subscription?.status === 'active'? 'bg-lime-500': subscription?.status === 'cancelled'? 'bg-red-700': subscription?.status === 'on_trial'? 'bg-yellow-700' : 'bg-blue-500'}`}></div>
                  {subscription.status}
                </div>
                {subscription.status === 'active' &&
                  <div>
                    Renews on <span className=''>{formatDate(subscription.renewsAt)}</span>
                  </div>
                }
                {subscription.status === 'cancelled' &&
                  <div>
                    Ends on <span>{formatDate(subscription.endsAt)}</span>
                  </div>
                }
              </div>
            </div>
          }
        </>
        
      }
      
    </div>
  )
}

export default Subscription