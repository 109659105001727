import React, { useContext } from 'react'
import Alignment from '../comps/Alignment';
import Styles from '../comps/Styles';
import CanvasContext from '../../../../context/CanvasContext';
import Theme from '../comps/Theme';
import Links from '../comps/Links';
import Img from '../comps/Img';
import Text from '../comps/Text';
import Pages from '../comps/Pages';

const PropsPanel = () => {
  const {selectedState, pageState, isCanvasLoading} = useContext(CanvasContext);
  return (
    <div className="fixed z-10 left-64 w-64 pt-16 h-full bg-black border-r border-zinc-800 select-none">
      <div className="flex flex-col gap-5 p-4 px-6 h-full customScroll overflow-y-auto" >

        {isCanvasLoading || selectedState == pageState?
          <>
            <Theme/>
            <Pages/>
          </>
          :
          <>
            <Alignment/>
            <Links />
            <Img />
            <Text />
            <Styles />
          </>
        }
      </div>
    </div>
  )
}

export default PropsPanel