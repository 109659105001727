import React, { useContext, useEffect, useState } from 'react'
import DialogBox from './DialogBox';
import CanvasContext from '../../../../context/CanvasContext';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import { BiLoaderAlt } from 'react-icons/bi';
import { IoCheckmark } from "react-icons/io5";
import { RxCross2} from "react-icons/rx";
import { FaAngleDown } from 'react-icons/fa6';
import { MdSettings } from "react-icons/md";
import { IoGlobeOutline } from "react-icons/io5";
import { useParams, useSearchParams } from 'react-router-dom';
import { FaCheckCircle } from "react-icons/fa";
import { FaRegEdit } from "react-icons/fa";


const SettingsBox = () => {
  const { siteId } = useParams();
  const [searchParams] = useSearchParams();
  const {siteRecord, setSiteRecord, settingsBoxAppear, setSettingsBoxAppear, } =  useContext(CanvasContext);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [saving, setSaving] = useState(false);
  const [saveErr, setSaveErr] = useState("");
  const [saveSuccess, setSaveSuccess] = useState(false);


  const [customDomain, setCustomDomain] = useState("");
  const [isValidInputDomain, setIsValidInputDomain] = useState(false);

  const [isConnecting, setIsConnecting] = useState(false);
  const [connectErr, setConnectErr] = useState("");
  const [connectSuccess, setConnectSuccess] = useState("");
  
  const [isConnected, setIsConnected] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const [selectedSettingsPage, setSelectedSettingsPage] = useState("/");
  useEffect(()=>{
    setSelectedSettingsPage(searchParams.get("page")||"/")
    setSaveErr("");
    setSaveSuccess(false);
    setSaving(false);
  },[searchParams])

  const [pagesDropdown, setPagesDropdown] = useState(false);
  
  useEffect(()=>{
    if (siteRecord._id && siteRecord.customDomain) {
      setCustomDomain(siteRecord.customDomain);
      setIsValidInputDomain(true);
      setIsConnected(true);
    }
  },[siteRecord])

  const handleDomainInputChange = (inputDomain)=>{
    const regex = /^(?![0-9]+$)(?!.*-$)(?!-)[a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{1,63})*\.[a-zA-Z]{2,}$/;
    // Check if the current input value matches the pattern
    if (regex.test(inputDomain)) {
      setIsValidInputDomain(true);
    } else {
      setIsValidInputDomain(false);
    }
    setCustomDomain(inputDomain)
  }

  const connectNewDomain = async ()=>{
    setConnectSuccess("");
    setIsConnecting(true);
    setConnectErr("");
    try {
      const resp = await axiosPrivate.post("/website/connect-domain", {websiteId: siteId, domain:customDomain});
      setConnectSuccess("Domain connected")
      setIsConnected(true);
      setSiteRecord(record=>{return {...record, customDomain:customDomain}})
    } catch (err) {
      console.log(err);
      console.log(err.status);
      if (err.response?.status===400) {
        setConnectErr("Website not found");
      } else if (err.response?.status===500 && err.response.data.message == "CNAME_MISMATCH") {
        setConnectErr("CNAME record not mathcing. Please try after some delay");
      } else if (err.response?.status===500 && err.response.data.message == "CF_ADD_ERR") {
        setConnectErr("Unable to add host. Please try again after some delay");
      } else {
        setConnectErr("SSL genration failed Try Later")
      }
    } finally {
      setIsConnecting(false);
    }
  }

  const removeDomain = async ()=>{
    setConnectSuccess(false);
    setIsConnecting(true);
    setConnectErr("");
    try {
      const pages = {};
      pages[selectedSettingsPage] = {title, description};
      const resp = await axiosPrivate.post("/website/remove-domain", {websiteId: siteId});
      setConnectSuccess("Domain removed");
      setIsConnected(false);
      setSiteRecord(record=>{return {...record, customDomain:null}});
    } catch (err) {
      console.log(err);
      setConnectErr("Unable to remove domain!")
    } finally {
      setIsConnecting(false);
    }
  }

  useEffect(()=>{
    if (siteRecord?.devPages && siteRecord.devPages[selectedSettingsPage]) {
      setTitle(siteRecord.devPages[selectedSettingsPage].title);
      setDescription(siteRecord.devPages[selectedSettingsPage].description);
    }
  },[selectedSettingsPage, siteRecord])

  const updateMetadata = async ()=>{
    setSaveSuccess(false);
    setSaving(true);
    setSaveErr("");
    try {
      const pages = {};
      pages[selectedSettingsPage] = {title, description};
      const resp = await axiosPrivate.patch("/website", {id: siteId, pages});
      if (resp.status == 200) {
        setSaveSuccess(true)
      } else {
        setSaveErr("Error");
      }
    } catch (err) {
      console.log(err);
      setSaveErr("Error");
    } finally {
      setSaving(false);
    }
  }

  return (
    <DialogBox appear={settingsBoxAppear} setAppear={setSettingsBoxAppear}>
      <div className=''>
        <div className='bg-black border border-zinc-800 flex flex-col gap-5 text-zinc-300 fixed left-[515px] top-1 z-20 px-6 py-3 rounded-xl text-xs w-[650px]'>
          <p className='font-semibold text-base flex items-center gap-1 border-b border-zinc-800 pb-1'>
            <MdSettings className='w-4 h-auto'/>Settings
          </p>
          <div className='text-sm'>
            <div className='grid grid-cols-3 gap-y-3 items-center'>
              <p>Select Page</p>
              {siteRecord?.devPages &&
                <div className="col-span-2 relative">
                  <button 
                    className="flex justify-center items-center gap-2 text-zinc-100 bg-zinc-800 rounded-lg py-2 px-4 text-sm w-full"
                    onClick={()=>{setPagesDropdown(!pagesDropdown)}}
                  >
                    <div className='flex flex-grow items-center text-left whitespace-nowrap overflow-clip overflow-ellipsis'>
                      {selectedSettingsPage}
                    </div>
                    <FaAngleDown className='w-3 h-3'/>
                  </button>
                  <DialogBox appear={pagesDropdown} setAppear={setPagesDropdown}>
                    <div className='absolute p-1 bg-zinc-900 text-zinc-100 w-full text-sm mt-1 z-50 max-h-32 overflow-y-auto rounded-md flex flex-col items-center'>
                      {
                        Object.keys(siteRecord.devPages).map(page => 
                          <button
                            key={page}
                            className={`hover:bg-lime-600 duration-200  rounded-md px-3 py-1 w-full justify-left flex items-center gap-2`}
                            onClick={()=>{setSelectedSettingsPage(page);setPagesDropdown(false)}}
                          >
                            {page}
                          </button>
                        )
                      }
                    </div>
                  </DialogBox>
                </div>
              }
              <p>Title</p>
              <div className='col-span-2 relative border-transparent'>
                <input type="text" value={title} onChange={(e)=>setTitle(e.target.value)} placeholder='Page title...' className='w-full rounded-lg py-2 px-4 bg-zinc-900 outline-none border border-transparent focus:border-lime-600  placeholder:text-zinc-400'/>
              </div>
              <p>Description</p>
              <div className='col-span-2'>
                <textarea rows={2} type="text" value={description} onChange={(e)=>setDescription(e.target.value)} placeholder='Page description...' className='bg-zinc-900 rounded-lg py-2 px-4 w-full outline-none border border-transparent focus:border-lime-600 resize-none max-h-32 customScroll'/>
              </div>
              <div></div>
              
              <button disabled={saving? true:false} onClick={()=>updateMetadata()} className='w-full bg-lime-600 rounded-lg py-2 px-3 hover:bg-lime-500 disabled:bg-zinc-700 duration-200 font-bold'>Save</button>
              <div> 
                {saving &&
                  <div className='flex gap-1 ml-4'><BiLoaderAlt className='w-5 h-auto animate-spin'/>Loading ...</div>
                }
                {saveErr &&
                  <div className='flex gap-1 ml-4 text-red-900'>
                  <RxCross2 className="w-5 h-auto"/>{saveErr}
                  </div>
                }
                {saveSuccess &&
                  <div className='flex gap-1 ml-4 text-lime-600'>
                    <IoCheckmark className='w-5 h-auto'/>Saved
                  </div>
                }
              </div>
            </div>
          </div>
          <p className='font-semibold text-base flex items-center gap-1 border-b border-zinc-800 pb-1'>
            <IoGlobeOutline className='w-4 h-auto'/>Custom Domain
          </p>
          <div className='text-sm'>
            <div className='grid grid-cols-3 gap-y-4 items-center'>
              <p>Your Domain</p>
              <div className='col-span-2 relative border-transparent'>
                {isConnected?
                <div className='flex justify-between items-center'>
                  <div className='flex items-center gap-2'>
                    <a href={`https://${customDomain}`} target="_blank" className='font-semibold text-white hover:underline flex justify-start tracking-wider items-center gap-1'>
                      https://{customDomain}
                    </a>
                    <span className='flex gap-1 items-center text-lime-500 font-semibold'><FaCheckCircle className='w-4 h-auto '/> Connected</span>
                  </div>
                  <button onClick={()=>setIsConnected(false)} className='mr-1 flex items-center gap-1 text-zinc-400 hover:text-zinc-200 hover:underline'><FaRegEdit className='w-4 h-auto'/>Edit</button>
                </div>
                :
                <input type="text" value={customDomain} onChange={(e)=>{handleDomainInputChange(e.target.value)}} placeholder='example.com' className={`w-full rounded-lg py-2 px-4 bg-zinc-900 outline-none border border-transparent placeholder:text-zinc-500 ${ isValidInputDomain? "focus:border-lime-600":"focus:border-red-900"}`}/>
                }
              </div>
              <p></p>
              <div className='col-span-2 text-zinc-400 tracking-wide space-y-3'>
                <p>Publishes this site to a <span className='text-zinc-300'>custom domain</span> you own. Add the host records given below via your domain registrar and then enter your domain above and click Connect.</p>
                <p>Note: DNS changes takes time to propagate. Allow upto an hour for changes to take effect</p>
                <div className='grid grid-cols-6 w-full bg-zinc-900 px-3 py-2 rounded-md'>
                  <div className='border-b border-zinc-700 p-2 col-span-2 text-zinc-200'>Record Type</div>
                  <div className='border-b border-zinc-700 p-2 text-zinc-200'>Host</div>
                  <div className='border-b border-zinc-700 p-2 col-span-3 text-zinc-200'>Target</div>
                  <div className=' border-zinc-800 p-2 col-span-2'>CNAME</div>
                  <div className=' border-zinc-800 p-2'>@</div>
                  <div className=' border-zinc-800 p-2 col-span-3 '>cname.makeweb.live</div>
                </div>
              </div>
              <div></div>
              {isConnected?
                <button onClick={removeDomain} className='w-full bg-red-900 rounded-lg py-2 px-3 hover:bg-red-700 duration-200 font-bold' >Remove Domain</button>
                :
                <button onClick={connectNewDomain} className='w-full bg-lime-600 disabled:bg-lime-900 rounded-lg py-2 px-3 hover:bg-lime-500 duration-200 font-bold' disabled={!isValidInputDomain} >Connect Domain</button>
              }
              
              <div> 
                {isConnecting &&
                  <div className='flex gap-1 ml-4'><BiLoaderAlt className='w-5 h-auto animate-spin'/>Validating ...</div>
                }
                {connectErr &&
                  <div className='flex gap-1 ml-2 text-red-900'>
                    <RxCross2 className="w-5 h-auto"/>{connectErr}
                  </div>
                }
                {connectSuccess &&
                  <div className='flex gap-1 ml-4 text-lime-600'>
                    <IoCheckmark className='w-5 h-auto'/>{connectSuccess}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  )
}

export default SettingsBox